.content-page {
  img.hero {
    width: 100%;
  }
  .hashText {
    margin: 2.5em auto 1em;
  }
  .content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    grid-template-rows: auto;
    grid-gap: 0;
    justify-content: center;

    .content-item {
      position: relative;
      // max-width: 250px;
      border-radius: 0;
      border: 0;
      background: #2065c3;
      overflow: hidden;
      a {
        text-decoration: none;
        color: #585858;
        .card-img-top {
          height: 20em;
          object-fit: cover;
          object-position: bottom;
        }
      }
      .card-body {
        display: block;
        padding: 0;
        position: absolute;
        bottom: 0;
        background: #fff;
        width: 100%;
        transition: transform 0.25s ease-out;
        transform: translateY(100%);
        .card-title {
          color: #000;
          text-decoration: none;
          margin: 0;
          font-size: 16px;
          font-weight: 600;
        }
        .card-text {
          float: left;
          width: 100%;
          padding: 2.5px;
          background-color: #f1f1f1;
          border-top: 1px solid #ccc;
        }
        .btn-social {
          display: table;
          float: left;
          width: calc((100% / 3) - 4%);
          margin: 2%;
          height: 30px;
          padding: 2px;
          color: #585858;
          text-align: center;
          text-decoration: none;
          background: #fff;
          border: 1px solid #ccc;
          border-radius: 6px;
        }
      }
      &:hover .card-body {
        transform: translateY(0);
      }
      // &:hover {
      //   box-shadow: 0 0 2px #000;
      //   z-index: 2;
      //   a {
      //     .content {
      //       display: block;
      //     }
      //   }
      // }
    }
  }
  // tiktok
  .content-tiktok {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    grid-template-rows: auto;
    grid-gap: 10px;
    justify-content: center;
    margin-bottom: 2.5em;

    .content-item {
      border-radius: 0;
      border: 0;max-width: 56vh;
     
      .parent {
        left: 0; 
        width: 100%; 
        height: 0; 
        position: relative; 
        padding-bottom: 165.7778%; 
        padding-top: 120px;
        iframe {
          top: 0; 
          left: 0; 
          width: 100%; 
          height: 100%; 
          position: absolute; 
          border: 0;
        }
      }    
    }
  }
}
